import * as Yup from 'yup'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {register} from '../core/_requests'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import PreventAutoComplete from '../../../components/PreventAutoComplete'
import clsx from 'clsx'
import SatkerSelect from './SatkerSelect'
import OfficerRankSelect from './OfficerRankSelect'
import {InputGroup} from 'react-bootstrap'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'
import {Link} from 'react-router-dom'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {intervalRefreshRecaptcha} from '../../../../_metronic/helpers'
import {scrollToFieldError} from '../../../utils/view'

const initialValues: any = {
  name: '',
  email: '',
  password: '',
  changepassword: '',
  nrp: '',
  nik: '',
  satkerId: undefined,
  officerRankId: undefined,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Email is required'),
  nrp: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(25, 'Maximum 25 symbols')
    .required('NRP is required'),
  nik: Yup.string()
    .matches(/^[0-9]+$/, 'NIK must be a number')
    .length(16, 'NIK length must be 16')
    .required('NIK is required'),
  satkerId: Yup.number()
    .required('Satker is required'),
  officerRankId: Yup.number()
    .required('Officer Rank is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

const isUseCaptcha = process.env.REACT_APP_USE_CAPTCHA === 'true'
const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN ?? ''

export function RegistrationForm() {
  const [loading, setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [refreshCaptcha, setRefreshCaptcha] = useState<boolean>(false)
  const errorRef = useRef<HTMLDivElement|null>(null)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStatus(null)
      setLoading(true)
      try {
        let request = {captchaToken: '', ...values}
        if (isUseCaptcha) {
          request = {captchaToken, ...values}
        }

        await register(request)
        setShowInfo(true)
        setSubmitting(false)
        setLoading(false)
      } catch (error: any) {
        console.error(error.message)
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
        setRefreshCaptcha(!refreshCaptcha)
        errorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
  })

  const onVerify = useCallback((token) => {
    setCaptchaToken(token)
  }, [])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    const intervalId = setInterval(() => {
      setRefreshCaptcha((r) => !r)
    }, intervalRefreshRecaptcha)


    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
  }

  return (
    <>
      {!showInfo && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <PreventAutoComplete />
          {/* begin::Heading */}

          <div ref={errorRef}>
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
          </div>

          {/* begin::Form group Name */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Full Name</label>
            <input
              placeholder='Full name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name + ''}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div className='fv-row mb-8'>
            {/* begin::Form group NIK */}
            <label className='form-label fw-bolder text-dark fs-6 required'>NIK</label>
            <input
              placeholder='NIK'
              type='text'
              autoComplete='off'
              maxLength={16}
              {...formik.getFieldProps('nik')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.nik && formik.errors.nik,
                },
                {
                  'is-valid': formik.touched.nik && !formik.errors.nik,
                }
              )}
            />
            {formik.touched.nik && formik.errors.nik && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nik + ''}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>

          <div className='fv-row mb-8'>
            {/* begin::Form group NRP */}
            <label className='form-label fw-bolder text-dark fs-6 required'>NRP</label>
            <input
              placeholder='NRP'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('nrp')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.nrp && formik.errors.nrp,
                },
                {
                  'is-valid': formik.touched.nrp && !formik.errors.nrp,
                }
              )}
            />
            {formik.touched.nrp && formik.errors.nrp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.nrp + ''}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>

          <div className='fv-row mb-8'>
            {/* begin::Form group Satker */}
            <label className='form-label fw-bolder text-dark fs-6 required'>Satker</label>
            <SatkerSelect
              field={formik.getFieldProps('satkerId')}
              form={formik}
              className={clsx(
                {
                  'is-invalid': formik.touched.satkerId && formik.errors.satkerId,
                },
                {
                  'is-valid': formik.touched.satkerId && !formik.errors.satkerId,
                }
              )}
            />
            {formik.touched.satkerId && formik.errors.satkerId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.satkerId + ''}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>

          <div className='fv-row mb-8'>
            {/* begin::Form group Officer Rank */}
            <label className='form-label fw-bolder text-dark fs-6 required'>Officer Rank</label>
            <OfficerRankSelect
              field={formik.getFieldProps('officerRankId')}
              form={formik}
              className={clsx(
                {
                  'is-invalid': formik.touched.officerRankId && formik.errors.officerRankId,
                },
                {
                  'is-valid': formik.touched.officerRankId && !formik.errors.officerRankId,
                }
              )}
            />
            {formik.touched.officerRankId && formik.errors.officerRankId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.officerRankId + ''}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>

          {/* begin::Form group Email */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email + ''}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
              <div className='position-relative mb-3'>
                <InputGroup>
                  <input
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder='Password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <InputGroup.Text role='button' onClick={togglePasswordVisibility}>
                    <i className={!isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                  </InputGroup.Text>
                </InputGroup>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password + ''}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>Use 8 or more characters.</div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
            <InputGroup>
              <input
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                placeholder='Password confirmation'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              <InputGroup.Text role='button' onClick={toggleConfirmPasswordVisibility}>
                <i className={!isConfirmPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
              </InputGroup.Text>
            </InputGroup>
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.changepassword + ''}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <LoadingButtonWrapper isLoading={loading}>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-danger w-100 mb-5'
                disabled={loading && formik.isValid}
              >
                <span className='indicator-label'>Submit</span>
              </button>
            </LoadingButtonWrapper>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-danger w-100 mb-5'
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* end::Form group */}
          {isUseCaptcha && (
            <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshCaptcha}
                action='register'
              />
            </GoogleReCaptchaProvider>
          )}
        </form>
      )}

      {showInfo && (
        <div className='text-center'>
          <h3>
            Your application has been submitted and will be reviewed by Admin Staff. Approval or
            rejection information will be sent to your email.
          </h3>

          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-danger w-100 mb-5 mt-14'
            >
              Back to Login
            </button>
          </Link>
        </div>
      )}
    </>
  )
}
