import {generateGUID} from './string'

const UNIQUE_BROWSER_KEY = '_brkey'

const setUniqueBrowser = (value: any) => {
  try {
    const lsValue = btoa(JSON.stringify(value))
    localStorage.setItem(`${UNIQUE_BROWSER_KEY}`, lsValue)
  } catch (e) {}
}

const getUniqueBrowser = () => {
  try {
    const res = localStorage.getItem(`${UNIQUE_BROWSER_KEY}`)
    if (!res) return null

    return JSON.parse(atob(res))
  } catch (e) {
    return null
  }
}

const getData = (userId = 0) => {
  const {userAgent, platform, language, hardwareConcurrency, maxTouchPoints, vendor} = navigator

  // Menggunakan informasi dari browser untuk menghasilkan string ID
  const data = [
    userAgent,
    platform,
    language,
    hardwareConcurrency,
    maxTouchPoints,
    vendor,
    userId,
  ].join('::')

  return data
}

const getFingerprint = (userId = 0) => {
  // const data = getData(userId)

  // // Hashing data untuk menghasilkan ID yang lebih pendek
  // let hash = 0
  // for (let i = 0; i < data.length; i++) {
  //   const char = data.charCodeAt(i)
  //   hash = (hash << 5) - hash + char
  //   hash |= 0 // Mengonversi ke 32bit integer
  // }

  // return hash.toString()

  const uniqueId = getUniqueBrowser()
  if (!uniqueId) {
    const guid = generateGUID()
    setUniqueBrowser(guid)
    return guid
  }

  return uniqueId
}

const getFingerprintDetails = (userId = 0) => {
  return getData(userId)
}

export {getFingerprint, getFingerprintDetails, getUniqueBrowser, setUniqueBrowser}
