import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useState} from 'react'
import {ForgotPasswordRequestType} from '../core/_model'
import {sendRequestForgotPassword} from '../core/_request'
import {Link} from 'react-router-dom'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import React from 'react'
import {intervalRefreshRecaptcha} from '../../../../_metronic/helpers'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .email('Email not valid')
    .required('Email is required'),
})

const initialForgotPassword: ForgotPasswordRequestType = {
  email: '',
}

const isUseCaptcha = process.env.REACT_APP_USE_CAPTCHA === 'true'
const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN ?? ''

const ForgotPasswordEmailForm: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isError, setError] = useState<boolean>(false)
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [refreshCaptcha, setRefreshCaptcha] = useState<boolean>(false)

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshCaptcha((r) => !r)
    }, intervalRefreshRecaptcha)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token)
  }, [])

  const formik = useFormik({
    initialValues: initialForgotPassword,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        setStatus(undefined)
        setError(false)

        let request = {...values, captchaToken: ''}

        if (isUseCaptcha) {
          request = {...values, captchaToken}
        }

        const response = await sendRequestForgotPassword(request)

        if (response.code === '1003') {
          setStatus('Email not registered.')
          setError(true)
        } else if (response.code === '4041') {
          setStatus(response.message)
          setError(true)
        } else {
          setStatus('Please kindly to check your email.')
          setError(false)
        }
      } catch (error: any) {
        setError(true)
        if (error.code === '1003') {
          setStatus('Email not registered.')
        } else if (error.code === '4041') {
          setStatus(error.message)
          setError(true)
        } else {
          setStatus(error.message)
        }
        setRefreshCaptcha(!refreshCaptcha)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        <div className='text-center mb-8'>
          <h1 className='text-dark fw-bolder mb-3'>Forget Password</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Use your active account's email</div>
        </div>

        {formik.status && isError && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {formik.status && !isError && (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        <div className='fv-row mb-3'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='text'
            name='email'
            autoComplete='off'
            tabIndex={1}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3'>
          <div />
          <Link to='/auth' className='link-primary'>
            Login instead
          </Link>
        </div>

        <div className='d-grid mb-3'>
          <LoadingButtonWrapper isLoading={loading}>
            <button
              type='submit'
              className='btn btn-danger'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Submit</span>
            </button>
          </LoadingButtonWrapper>
        </div>
      </form>
      {isUseCaptcha && (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshCaptcha}
            action='send_email_forgot_password'
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  )
}

export default ForgotPasswordEmailForm
