import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {auth} from '../core/_requests'
import {useAuth} from '../core/Auth'
import React, {useCallback, useEffect, useState} from 'react'
import {InputGroup} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {intervalRefreshRecaptcha} from '../../../../_metronic/helpers'
import Connector from '../../../../_metronic/helpers/signalr-connection'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'
import {parseJsonAndPascalToCamel} from '../../../utils/array'
import {getFingerprintDetails} from '../../../utils/fingerprint'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const isUseCaptcha = process.env.REACT_APP_USE_CAPTCHA === 'true'
const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN ?? ''

export function Login() {
  const {saveAuth, setCurrentUser, setOtp} = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [refreshCaptcha, setRefreshCaptcha] = useState<boolean>(false)
  const connector = Connector()

  const navigate = useNavigate()

  const onVerify = useCallback((token) => {
    setCaptchaToken(token)
  }, [])

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshCaptcha((r) => !r)
    }, intervalRefreshRecaptcha)

    connector.unsubscribeAll()

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const formik = useFormik({
    initialValues: {username: '', password: ''},
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        setStatus(undefined)

        let authRequest = {captchaToken: '', ...values}
        if (isUseCaptcha) {
          authRequest = {captchaToken, ...values}
        }

        const response = await auth(authRequest)
        const {token, userInfo, guid, isWaitOtp} = response.data

        setStatus(undefined)

        const currentUser = {
          username: userInfo.loginName,
          email: userInfo.additionalInfo.email,
          majorName: userInfo.additionalInfo.studentMajorName,
          degreeName: userInfo.additionalInfo.studentMajorDegreeName,
          nim: userInfo.additionalInfo.studentMajorNim,
          id: userInfo.id,
          name: userInfo.name,
          roleId: userInfo.roles[0].id,
          roleName: userInfo.roles[0].name,
          limitFileSize: userInfo.additionalInfo.limitFileSize,
          limitFileSizeMb: userInfo.additionalInfo.limitFileSize / 1048576,
          staffMajors: userInfo.additionalInfo.staffMajorList
            ? parseJsonAndPascalToCamel(userInfo.additionalInfo.staffMajorList)
            : null,
          isQuizCreator: userInfo?.roles.some((f) => f.code === 'QC'),
        }

        if (isWaitOtp) {
          setOtp(
            JSON.stringify({
              guid: guid,
              userId: userInfo.id,
              currentUser: currentUser,
              apiToken: token,
              captchaToken: '',
            })
          )

          navigate('otp')
        } else {
          setCurrentUser(currentUser)

          saveAuth({api_token: token})
        }

        // const menu = await getMenu()
        // if (menu) {
        //   setCurrentMenu(menu.data.data)
        // }
      } catch (error) {
        saveAuth(undefined)
        // @ts-ignore
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
        formik.setFieldValue('password', '')
        setRefreshCaptcha(!refreshCaptcha)
      }
    },
  })

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const printBrowserId = () => {
    console.log(getFingerprintDetails())
  }

  // useEffect(() => {
  //   function checkSelection() {
  //     const selection = window?.getSelection()?.toString() // Get selected text
  //     if (selection) {
  //       alert(`You selected: "${selection}"`)
  //     }
  //   }

  //   // Event listener for selection change
  //   document.addEventListener('selectionchange', checkSelection)
  // }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3' onClick={printBrowserId}>
          Sign In
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Use your active account</div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        ''
      )}

      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          name='username'
          autoComplete='off'
          tabIndex={1}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
        <div className='position-relative'>
          <InputGroup>
            <input
              placeholder='Password'
              {...formik.getFieldProps('password')}
              type={isPasswordVisible ? 'text' : 'password'}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              tabIndex={2}
              autoComplete='off'
            />

            <InputGroup.Text role='button' onClick={togglePasswordVisibility}>
              <i className={!isPasswordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
            </InputGroup.Text>
          </InputGroup>

          {/* <input
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            tabIndex={2}
          /> */}
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}

          {/* <span
            onClick={togglePasswordVisibility}
            style={{
              cursor: 'pointer',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              right: 0,
              top: 0,
              width: 30,
            }}
          >
            <i className={isPasswordVisible ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'}></i>
          </span> */}
        </div>
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-3'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>

      <div className='d-grid mb-3'>
        <div className='w-100'>
          <LoadingButtonWrapper isLoading={loading}>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-danger w-100'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Log in</span>
            </button>
          </LoadingButtonWrapper>
        </div>
        <Link to='/register' className='text-center mt-4' style={{justifySelf: 'center'}}>
          <button
            type='button'
            id='kt_sign_in_submit'
            className='btn btn-link btn-text-danger'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>Sign Up</span>
          </button>
        </Link>
        {isUseCaptcha && (
          <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
            <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshCaptcha} action='login' />
          </GoogleReCaptchaProvider>
        )}
      </div>
    </form>
  )
}
