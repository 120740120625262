import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {validateOtp} from '../core/_requests'
import {useAuth} from '../core/Auth'
import clsx from 'clsx'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {intervalRefreshRecaptcha} from '../../../../_metronic/helpers'
import {OtpRequest} from '../core/AuthHelpers'
import {useNavigate} from 'react-router-dom'
import LoadingButtonWrapper from '../../../components/LoadingButtonWrapper'

const isUseCaptcha = process.env.REACT_APP_USE_CAPTCHA === 'true'
const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN ?? ''

export function Otp() {
  const [captchaToken, setCaptchaToken] = useState<string>('')
  const [refreshCaptcha, setRefreshCaptcha] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    const data = getOtp()

    if (data === null) {
      navigate('/auth')
    }

    const intervalId = setInterval(() => {
      setRefreshCaptcha((r) => !r)
    }, intervalRefreshRecaptcha)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const {getOtp, saveAuth, setCurrentUser} = useAuth()

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token)
  }, [])

  const formik = useFormik({
    initialValues: {otp: ''},
    validationSchema: Yup.object().shape({
      otp: Yup.string()
        .min(1, 'OTP Minimal 1 characters')
        .max(24, 'OTP Maximal 24 characters')
        .required('OTP is required'),
    }),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        setStatus(undefined)

        const localStorageValue = getOtp()

        if (localStorageValue === null) return

        const req: OtpRequest = {
          guidToken: localStorageValue.guid,
          userId: localStorageValue.userId,
          otp: values.otp,
          captchaToken: isUseCaptcha ? captchaToken : '',
        }

        const response = await validateOtp(req)
        if (response.data === true) {
          setCurrentUser(localStorageValue.currentUser)
          saveAuth({api_token: localStorageValue.apiToken})
        } else {
          setStatus(response.message)
        }
      } catch (error: any) {
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
        setRefreshCaptcha(!refreshCaptcha)
        formik.setFieldValue('otp', '')
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>OTP</h1>
          <div className='text-gray-500 fw-semibold fs-6'>Input OTP code from email/Telegram</div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
          <input
            placeholder='OTP'
            {...formik.getFieldProps('otp')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.otp && formik.errors.otp},
              {
                'is-valid': formik.touched.otp && !formik.errors.otp,
              }
            )}
            type='text'
            name='otp'
            autoComplete='off'
          />
          {formik.touched.otp && formik.errors.otp && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.otp}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <LoadingButtonWrapper isLoading={loading}>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-danger'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Validate</span>
            </button>
          </LoadingButtonWrapper>
        </div>
        {/* end::Action */}

        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
      </form>
      {isUseCaptcha && (
        <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshCaptcha}
            action='validate_otp'
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  )
}
