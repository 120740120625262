import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_BASE_API_URL
const URL = `${API_URL}/appsetting`

const getSetting = (): Promise<Response<any[]>> => {
  return axios.get(`${URL}`).then((d: AxiosResponse<Response<any[]>>) => d.data)
}

const updateSetting = (data: any[]): Promise<Response<number>> => {
  return axios.put(`${URL}`, data).then((resp) => resp.data)
}
export {getSetting, updateSetting}
