import React, {useEffect, useState, memo} from 'react'
import {getSetting, updateSetting} from './_request'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import BlockLoading from '../../../components/BlockLoading'
import {showError, showSuccess} from '../../../utils/toast'

const InputField = memo(({label, value, onChange}: any) => {
  return (
    <div className='row mb-12'>
      <div className='col-12'>
        <label className='fw-bold fs-6 mb-4 required'>{label}</label>
        <input
          className='form-control'
          type='text'
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  )
})

const AppSettingForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any[]>([])

  const get = async () => {
    try {
      setIsLoading(true)
      const result = await getSetting()
      if (result.code === '0000') {
        setData(result.data || [])
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      const result = await updateSetting(data)
      if (result.code === '0000') {
        showSuccess('Update Setting Success')
      }
    } catch (e: any) {
      showError(e.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    get()
  }, [])

  const onValueChange = (index, value) => {
    setData((prevData) => prevData.map((item, i) => (i === index ? {...item, value} : item)))
  }

  return (
    <div>
      {isLoading && <BlockLoading fullBlocking={true} />}
      <div className='d-flex flex-row justify-content-between mb-8'>
        <h1 className='page-heading text-dark fw-bold fs-3 my-0'>App Setting</h1>
      </div>
      <div className='row'>
        <div className='col-6'>
          <KTCard>
            <KTCardBody className='py-4'>
              <form onSubmit={onSubmit}>
                {data?.map((m, i) => (
                  <InputField
                    key={i}
                    label={m.label}
                    value={m.value}
                    onChange={(value) => onValueChange(i, value)}
                  />
                ))}

                <div className='text-end'>
                  <button type='submit' className='btn btn-warning'>
                    Save
                  </button>
                </div>
              </form>
            </KTCardBody>
          </KTCard>
        </div>
      </div>
    </div>
  )
}

export default AppSettingForm
